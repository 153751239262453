<template>
  <transition-group
    name="list"
    tag="ul"
  >
    <li
      v-for="(alert, index) in alerts"
      :key="`alert-${index}`"
      :class="'alert alert-' + alert.alertType"
      @click="dismissAlert(index)"
    >
      {{ alert.message }}
      <button
        id="alert-dismiss"
        class="alert-dismiss"
      >
        <icon name="fas fa-times" />
      </button>
    </li>
  </transition-group>
</template>

<script>
import { dom, library } from '@fortawesome/fontawesome-svg-core';
import { faTimes } from '@fortawesome/free-solid-svg-icons/faTimes';
import Icon from './Icon.vue';

library.add(faTimes);

dom.i2svg();
dom.watch();

export default {
  name: 'Alert',
  components: {
    Icon,
  },
  props: {
    alerts: {
      type: Array,
      required: true,
    },
  },
  methods: {
    dismissAlert(index) {
      this.$emit('dismiss', index);
    },
  },
};
</script>

<style scoped lang="scss">
    .alert {
      @apply fixed inset-x-0 bottom-0 bg-white text-gray-500 p-4 text-center text-xl;
      z-index: 55;
    }

    .alert-success {
        @apply bg-green-500 text-white;
    }

    .alert-error {
        @apply bg-red-500 text-white;
    }

    .alert-warning {
        @apply bg-yellow-500 text-yellow-900;
    }

    .alert-dismiss {
        @apply cursor-pointer rounded-full absolute inset-y-0 right-0 block
        w-5 h-5 leading-5 text-center mt-2 mr-2 font-bold uppercase text-sm;
        background: rgba(255, 255, 255, 0.2);
    }
</style>
