<template>
  <div>
    <transition
      name="expand"
      @enter="enter"
      @after-enter="afterEnter"
      @leave="leave"
    >
      <div
        v-show="show || condition"
        :class="slotWrapperClass"
      >
        <slot />
      </div>
    </transition>
    <button
      v-if="showButton"
      :id="showButtonId"
      class="btn btn-primary btn-small m-2"
      @click="show = !show"
    >
      {{ show ? 'Hide' : 'Show' }}
    </button>
  </div>
</template>

<script>
export default {
  name: 'CollapseExpand',
  props: {
    condition: {
      type: Boolean,
      default: false,
    },
    showButton: {
      type: Boolean,
      default: false,
    },
    showButtonId: {
      type: String,
      default: '',
    },
    slotWrapperClass: {
      type: String,
      default: 'flex flex-col',
    },
  },
  emits: ['enter', 'leave'],
  data() {
    return {
      show: this.condition,
    };
  },
  methods: {
    enter() {
      const element = this.$el.childNodes[0];
      const { width } = getComputedStyle(element);
      element.style.width = width;
      element.style.position = 'absolute';
      element.style.visibility = 'hidden';
      element.style.height = 'auto';
      const { height } = getComputedStyle(element);
      element.style.width = null;
      element.style.position = null;
      element.style.visibility = null;
      element.style.height = 0;
      setTimeout(() => {
        element.style.height = height;
      });
      this.$emit('enter');
    },
    afterEnter() {
      const element = this.$el.childNodes[0];
      element.style.height = 'auto';
    },
    leave() {
      const element = this.$el.childNodes[0];
      const { height } = getComputedStyle(element);
      element.style.height = height;
      setTimeout(() => {
        element.style.height = 0;
      });
      this.$emit('leave');
    },
  },
};
</script>

<style>
  .expand-enter-active,
  .expand-leave-active {
    transition: height .5s ease-in-out;
    overflow: hidden;
  }

  .expand-enter,
  .expand-leave-to {
    height: 0;
  }
</style>
