<template>
  <div
    id="nav"
    v-click-outside="close"
    class="navigation-list"
    :class="[isToggled ? 'on' : 'off']"
  >
    <a
      id="navigation-toggle"
      ref="toggleButton"
      href="#"
      :class="'toggle' + (isToggled ? ' on' : ' off')"
      @click="toggle"
    >
      <icon
        v-if="isToggled"
        key="isOpen"
        name="fas fa-times"
      />
      <icon
        v-else
        key="isClosed"
        name="fas fa-bars"
      />
    </a>
    <div
      v-if="isToggled"
    >
      <div
        v-if="isLoggedIn"
        key="logged-in"
        class="flex flex-col"
      >
        <router-link
          id="nav-notifications"
          :to="{name: 'notifications'}"
          class="router-link"
        >
          <icon
            name="fas fa-bell"
            container-class="navigation-icon-container"
          />Notifications ({{ notification_count }})
        </router-link>
        <router-link
          id="nav-dashboard"
          :to="{name: 'dashboard'}"
          class="router-link"
        >
          <icon
            name="fas fa-house-user"
            container-class="navigation-icon-container"
          />Dashboard
        </router-link>
        <router-link
          id="nav-employees"
          :to="{name: 'employees'}"
          class="router-link"
        >
          <icon
            name="fas fa-users"
            container-class="navigation-icon-container"
          />Employees
        </router-link>
        <router-link
          id="nav-feed"
          :to="{name: 'feed'}"
          class="router-link"
        >
          <icon
            name="fas fa-clone"
            container-class="navigation-icon-container"
          />Feed
        </router-link>
        <router-link
          id="nav-videos"
          :to="{name: 'videos'}"
          class="router-link"
        >
          <icon
            name="fas fa-film"
            container-class="navigation-icon-container"
          />Videos
        </router-link>
        <router-link
          v-if="can('upload')"
          id="nav-upload"
          :to="{name: 'upload'}"
          class="router-link"
        >
          <icon
            name="fas fa-file-upload"
            container-class="navigation-icon-container"
          />Upload
        </router-link>
        <a
          id="nav-settings"
          href="#"
          class="router-link"
          @click.prevent="settings = !settings"
        >
          <icon
            name="fas fa-cogs"
            container-class="navigation-icon-container"
          />Settings
        </a>
        <collapse-expand
          slot-wrapper-class="flex flex-col px-2 ml-4 navigation-subnav"
          :condition="settings"
        >
          <router-link
            id="nav-authentication"
            :to="{name: 'authentication'}"
            class="router-link"
          >
            <icon
              name="fas fa-wrench"
              container-class="navigation-icon-container"
            />Authentication
          </router-link>
          <router-link
            id="nav-profile"
            :to="{name: 'profile'}"
            class="router-link"
          >
            <icon
              name="fas fa-user"
              container-class="navigation-icon-container"
            />Profile
          </router-link>
          <router-link
            v-if="can('upload')"
            id="nav-my-uploads"
            :to="{name: 'my-uploads'}"
            class="router-link"
          >
            <icon
              name="fas fa-images"
              container-class="navigation-icon-container"
            />My Uploads
          </router-link>
          <a
            id="logout"
            href="#"
            class="router-link"
            @click.prevent="$emit('logout')"
          >
            <icon
              name="fas fa-sign-out-alt"
              container-class="navigation-icon-container"
            />Logout
          </a>
        </collapse-expand>
        <router-link
          v-if="can('vote')"
          id="nav-vote"
          :to="{name: 'vote'}"
          class="router-link"
        >
          <icon
            name="fas fa-thumbs-up"
            container-class="navigation-icon-container"
          />Vote
        </router-link>
        <a
          v-if="canManage"
          id="nav-manage"
          href="#"
          class="router-link"
          @click.prevent="manage = !manage"
        >
          <icon
            name="fas fa-book"
            container-class="navigation-icon-container"
          />Manage
        </a>
        <collapse-expand
          slot-wrapper-class="flex flex-col px-2 ml-4 navigation-subnav"
          :condition="manage"
        >
          <router-link
            v-if="can('manage_users')"
            id="nav-manage-bios"
            :to="{name: 'manage-bios'}"
            class="router-link"
          >
            <icon
              name="fas fa-address-book"
              container-class="navigation-icon-container"
            />Bios
          </router-link>
          <router-link
            v-if="can('manage_categories')"
            id="nav-manage-categories"
            :to="{name: 'manage-categories'}"
            class="router-link"
          >
            <icon
              name="fas fa-layer-group"
              container-class="navigation-icon-container"
            />Categories
          </router-link>
          <router-link
            v-if="can('manage_users')"
            id="nav-manage-comments"
            :to="{name: 'manage-comments'}"
            class="router-link"
          >
            <icon
              name="fas fa-comment"
              container-class="navigation-icon-container"
            />Comments
          </router-link>
          <router-link
            v-if="can('manage_users')"
            id="nav-manage-likes"
            :to="{name: 'manage-likes'}"
            class="router-link"
          >
            <icon
              name="fas fa-heart"
              container-class="navigation-icon-container"
            />Likes
          </router-link>
          <router-link
            v-if="can('manage_categories')"
            id="nav-manage-tips-tricks-blurbs"
            :to="{name: 'manage-tips-trick-blurbs'}"
            class="router-link"
          >
            <icon
              name="fas fa-lightbulb"
              container-class="navigation-icon-container"
            />Tips Tricks & Blurbs
          </router-link>
          <router-link
            v-if="can('manage_uploads')"
            id="nav-manage-uploads"
            :to="{name: 'manage-uploads'}"
            class="router-link"
          >
            <icon
              name="fas fa-arrow-circle-up"
              container-class="navigation-icon-container"
            />Uploads
          </router-link>
          <router-link
            v-if="can('manage_users')"
            id="nav-manage-users"
            :to="{name: 'manage-users'}"
            class="router-link"
          >
            <icon
              name="fas fa-users"
              container-class="navigation-icon-container"
            />Users
          </router-link>
          <router-link
            v-if="can('manage_uploads')"
            id="nav-manage-videos"
            :to="{name: 'manage-videos'}"
            class="router-link"
          >
            <icon
              name="fas fa-film"
              container-class="navigation-icon-container"
            />Videos
          </router-link>
          <router-link
            v-if="can('manage_uploads')"
            id="nav-manage-votes"
            :to="{name: 'manage-votes'}"
            class="router-link"
          >
            <icon
              name="fas fa-poll"
              container-class="navigation-icon-container"
            />Votes
          </router-link>
        </collapse-expand>
      </div>
      <div
        v-else
        key="not-logged-in"
        class="flex flex-col"
      >
        <router-link
          id="nav-home"
          :to="{name: 'home'}"
          class="router-link"
        >
          <icon
            name="fas fa-home"
            container-class="navigation-icon-container"
          />Home
        </router-link>
      </div>
      <button
        id="toggle-darken"
        class="router-link navigation-mode-toggle"
        @click="$root.switchDarken"
      >
        <icon
          v-if="$root.darken"
          key="nav-lighten"
          name="fas fa-sun"
          container-class="navigation-icon-container"
        />
        <icon
          v-else
          key="nav-darken"
          name="fas fa-moon"
          container-class="navigation-icon-container"
        />
        {{ $root.darken ? 'Lighten' : 'Darken' }}
      </button>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import { library, dom } from '@fortawesome/fontawesome-svg-core';
import { faBars } from '@fortawesome/free-solid-svg-icons/faBars';
import { faTimes } from '@fortawesome/free-solid-svg-icons/faTimes';
import { faHome } from '@fortawesome/free-solid-svg-icons/faHome';
import { faHouseUser } from '@fortawesome/free-solid-svg-icons/faHouseUser';
import { faClone } from '@fortawesome/free-solid-svg-icons/faClone';
import { faFileUpload } from '@fortawesome/free-solid-svg-icons/faFileUpload';
import { faThumbsUp } from '@fortawesome/free-solid-svg-icons/faThumbsUp';
import { faSignOutAlt } from '@fortawesome/free-solid-svg-icons/faSignOutAlt';
import { faBook } from '@fortawesome/free-solid-svg-icons/faBook';
import { faLayerGroup } from '@fortawesome/free-solid-svg-icons/faLayerGroup';
import { faArrowCircleUp } from '@fortawesome/free-solid-svg-icons/faArrowCircleUp';
import { faUsers } from '@fortawesome/free-solid-svg-icons/faUsers';
import { faPoll } from '@fortawesome/free-solid-svg-icons/faPoll';
import { faSun } from '@fortawesome/free-solid-svg-icons/faSun';
import { faMoon } from '@fortawesome/free-solid-svg-icons/faMoon';
import { faFilm } from '@fortawesome/free-solid-svg-icons/faFilm';
import { faImages } from '@fortawesome/free-solid-svg-icons/faImages';
import { faBell } from '@fortawesome/free-solid-svg-icons/faBell';
import { faComment } from '@fortawesome/free-solid-svg-icons/faComment';
import { faHeart } from '@fortawesome/free-solid-svg-icons/faHeart';
import { faLightbulb } from '@fortawesome/free-solid-svg-icons/faLightbulb';
import { faCogs } from '@fortawesome/free-solid-svg-icons/faCogs';
import { faUser } from '@fortawesome/free-solid-svg-icons/faUser';
import { faWrench } from '@fortawesome/free-solid-svg-icons/faWrench';
import { faAddressBook } from '@fortawesome/free-solid-svg-icons/faAddressBook';
import vClickOutside from 'click-outside-vue3';
import Icon from './Icon.vue';
import CollapseExpand from '../transitions/collapse.vue';

library.add(
  faBars,
  faTimes,
  faHome,
  faHouseUser,
  faClone,
  faFileUpload,
  faThumbsUp,
  faSignOutAlt,
  faBook,
  faLayerGroup,
  faArrowCircleUp,
  faUsers,
  faPoll,
  faSun,
  faMoon,
  faFilm,
  faImages,
  faBell,
  faHeart,
  faComment,
  faLightbulb,
  faCogs,
  faUser,
  faWrench,
  faAddressBook,
);

dom.i2svg();
dom.watch();

export default {
  name: 'Navigation',
  components: {
    CollapseExpand,
    Icon,
  },
  directives: {
    clickOutside: vClickOutside.directive,
  },
  props: {
    isLoggedIn: {
      type: Boolean,
      required: true,
    },
    user: {
      type: Object,
      required: true,
    },
    isToggled: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['update:isToggled'],
  data() {
    return {
      settings: false,
      manage: false,
      notification_count: 0,
    };
  },
  computed: {
    canManage() {
      return this.user.role.manage_categories || this.user.role.manage_uploads || this.user.role.manage_users;
    },
  },
  mounted() {
    if (this.isLoggedIn) {
      const vm = this;
      axios.get('/api/notifications/count')
        .then((response) => {
          vm.notification_count = response.data;
        });
      this.$root.websocket
        .echo
        .channel(`notifications.${this.user.id}`)
        .listen('NotificationEvent', (e) => {
          vm.notification_count = e.count;
        });
    }
  },
  methods: {
    can(permission) {
      return this.user.role[permission];
    },
    toggle() {
      this.$emit('update:isToggled', !this.isToggled);
    },
    close() {
      this.$emit('update:isToggled', false);
    },
  },
};
</script>

<style scoped lang="scss">
  .navigation {
    &-list {
      @apply text-gray-800 text-lg px-4 py-2 absolute z-50;
      background: transparent;
      top: 0;
      left: 0;
      @screen md {
        @apply bg-white border-r border-gray-200 pt-4 border-b-0 relative mt-20;
        top: auto;
        left: auto;
      }
      @screen lg {
        @apply mt-24;
        }
      &.on {
        @apply flex-col justify-end bg-white border-r border-b border-solid border-gray-200;
        @screen md {
          @apply w-1/2 flex-col justify-end border-b-0;
        }
        @screen lg {
          @apply w-1/5;
        }
        @screen xl {
          @apply w-1/6;
        }
      }
      &.off {
        @apply flex-shrink;
      }
      a, button {
        @apply block w-full text-gray-700 no-underline
        text-left rounded-full py-4 px-4 font-bold
        transition duration-300 ease-in-out;
        &:visited {
          @apply text-gray-700 no-underline;
        }
        &:hover, &:active {
          @apply text-black bg-blue-200;
        }
        &.router-link-active {
          @apply relative font-bold text-green-500 bg-transparent cursor-default;
          &::after {
            @apply absolute inset-x-0 bottom-0 mb-1 h-1 bg-blue-200 mx-3;
            content: "";
          }
          &:hover, &:active, &:visited {
            @apply bg-transparent;
          }
        }
        &.toggle {
          @apply w-10 h-10 p-0 m-0 leading-10 text-center block;
        }
        &:focus {
          outline: none;
        }
      }
    }
  }
  .dark-mode {
    .navigation {
      &-list {
        @apply bg-blue-900 text-gray-200;
        @screen md {
          @apply border-blue-800;
        }
        &.on {
          @apply border-blue-800;
        }
        a, button {
          @apply text-gray-200;
          &:visited {
            @apply text-gray-200;
          }
          &:hover, &:active {
            @apply text-blue-800 bg-blue-100;
          }
          &.router-link-active {
            @apply text-green-400 bg-transparent;
            &::after {
              @apply bg-blue-600;
              content: "";
            }
          }
        }
      }
    }
  }
  .router-link {
    @apply whitespace-no-wrap;
  }
</style>
