const Home = () => import('./components/pages/Home.vue');
const Upload = () => import('./components/pages/Upload.vue');
const Dashboard = () => import('./components/pages/Dashboard.vue');
// const PageNotFound = () => import('./components/pages/PageNotFound.vue');
const Feed = () => import('./components/pages/Feed.vue');
const Videos = () => import('./components/pages/Videos.vue');
const VoteIndex = () => import('./components/pages/Vote/Index.vue');
const Vote = () => import('./components/pages/Vote/Vote.vue');
const ManageCategories = () => import('./components/pages/Manage/Categories.vue');
const ManageUploads = () => import('./components/pages/Manage/Uploads.vue');
const ManageUsers = () => import('./components/pages/Manage/Users.vue');
const ManageVotes = () => import('./components/pages/Manage/Votes.vue');
const ManageVideos = () => import('./components/pages/Manage/Videos.vue');
const Category = () => import('./components/pages/Manage/Category.vue');
const CategoryVotes = () => import('./components/pages/Manage/CategoryVotes.vue');
const MyUploads = () => import('./components/pages/MyUploads.vue');
const Notifications = () => import('./components/pages/Notifications.vue');
const Image = () => import('./components/pages/ImagePage.vue');
const ManageComments = () => import('./components/pages/Manage/Comments.vue');
const ManageLikes = () => import('./components/pages/Manage/Likes.vue');
const ManageTipsTricksBlurbs = () => import('./components/pages/Manage/TipsTricksAndBlurbs.vue');
const ManageBios = () => import('./components/pages/Manage/Bios.vue');
const Profile = () => import('./components/pages/Profile.vue');
const Employees = () => import('./components/pages/Employees.vue');
const Authentication = () => import('./components/pages/Authentication.vue');

export default [
  {
    name: 'login',
    path: '/login',
    component: Home,
    meta: { redirectIfAuthenticated: true },
  },
  {
    name: 'home',
    path: '/',
    component: Home,
    meta: { redirectIfAuthenticated: true },
  },
  {
    name: 'dashboard',
    path: '/dashboard',
    component: Dashboard,
    meta: { requiresAuth: true },
  },
  {
    name: 'employees',
    path: '/employees',
    component: Employees,
    meta: { requiresAuth: true },
  },
  {
    name: 'profile',
    path: '/profile',
    component: Profile,
    meta: { requiresAuth: true },
  },
  {
    name: 'upload',
    path: '/upload',
    component: Upload,
    meta: {
      requiresAuth: true,
      permission: 'upload',
    },
  },
  {
    name: 'feed',
    path: '/feed',
    component: Feed,
    meta: { requiresAuth: true },
  },
  {
    name: 'videos',
    path: '/videos',
    component: Videos,
    meta: { requiresAuth: true },
  },
  {
    name: 'vote',
    path: '/vote',
    component: VoteIndex,
    meta: {
      requiresAuth: true,
      permission: 'vote',
    },
  },
  {
    name: 'vote-for-category',
    path: '/vote-category-:id',
    component: Vote,
    props: true,
    meta: {
      requiresAuth: true,
      permission: 'vote',
    },
  },
  {
    name: 'manage-categories',
    path: '/manage-categories',
    component: ManageCategories,
    meta: {
      requiresAuth: true,
      permission: 'manage_categories',
    },
  },
  {
    name: 'manage-uploads',
    path: '/manage-uploads',
    component: ManageUploads,
    meta: {
      requiresAuth: true,
      permission: 'manage_uploads',
    },
  },
  {
    name: 'manage-users',
    path: '/manage-users',
    component: ManageUsers,
    meta: {
      requiresAuth: true,
      permission: 'manage_users',
    },
  },
  {
    name: 'manage-bios',
    path: '/manage-bios',
    component: ManageBios,
    meta: {
      requiresAuth: true,
      permission: 'manage_users',
    },
  },
  {
    name: 'manage-category',
    path: '/manage-category-:id',
    props: true,
    component: Category,
    meta: {
      requiresAuth: true,
      permission: 'manage_categories',
    },
  },
  {
    name: 'manage-votes',
    path: '/manage-votes',
    component: ManageVotes,
    meta: {
      requiresAuth: true,
      permission: 'manage_uploads',
    },
  },
  {
    name: 'manage-videos',
    path: '/manage-videos',
    component: ManageVideos,
    meta: {
      requiresAuth: true,
      permission: 'manage_uploads',
    },
  },
  {
    name: 'manage-category-votes',
    path: '/manage-votes-category-:id',
    props: true,
    component: CategoryVotes,
    meta: {
      requiresAuth: true,
      permission: 'manage_uploads',
    },
  },
  {
    name: 'manage-comments',
    path: '/manage-comments',
    component: ManageComments,
    meta: {
      requiresAuth: true,
      permission: 'manage_users',
    },
  },
  {
    name: 'manage-likes',
    path: '/manage-likes',
    component: ManageLikes,
    meta: {
      requiresAuth: true,
      permission: 'manage_users',
    },
  },
  {
    name: 'manage-tips-trick-blurbs',
    path: '/manage-tips-trick-blurbs',
    component: ManageTipsTricksBlurbs,
    meta: {
      requiresAuth: true,
      permission: 'manage_categories',
    },
  },
  {
    name: 'my-uploads',
    path: '/my-uploads',
    component: MyUploads,
    meta: {
      requiresAuth: true,
      permission: 'upload',
    },
  },
  {
    name: 'notifications',
    path: '/notifications',
    component: Notifications,
    meta: {
      requiresAuth: true,
    },
  },
  {
    name: 'authentication',
    path: '/authentication',
    component: Authentication,
    meta: {
      requiresAuth: true,
    },
  },
  {
    name: 'image',
    path: '/image-:id',
    props: true,
    component: Image,
    meta: {
      requiresAuth: true,
    },
  },
];
