import Echo from 'laravel-echo';
import Socket from 'socket.io-client';

export default class WebSocket {
  constructor() {
    this.echo = new Echo({
      broadcaster: 'socket.io',
      host: window.location.origin,
      client: Socket,
    });
  }
}
