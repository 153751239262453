<template>
  <span :class="'icon ' + containerClass">
    <i :class="name" />
  </span>
</template>

<script>
export default {
  name: 'Icon',
  props: {
    name: {
      type: String,
      required: true,
    },
    containerClass: {
      type: String,
      required: false,
      default: '',
    },
  },
};
</script>
