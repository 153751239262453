export default class Message {
  constructor(type, message) {
    this.type = type;
    this.message = message;
  }

  dispatch() {
    window.dispatchEvent(
      new CustomEvent(`${this.type}Message`, { detail: this.message }),
    );
  }
}
