<template>
  <div
    class="app"
    :class="[$root.darken ? 'dark-mode' : 'light-mode']"
  >
    <div
      class="app-masthead"
    >
      <div class="app-masthead-background" />
      <div
        class="app-masthead-logo mr-2"
        :class="[$root.darken ? 'we-are-pbc-white' : 'we-are-pbc']"
      />
    </div>
    <alert
      :alerts="alerts"
      @dismiss="removeAlert"
    />
    <navigation
      v-model:is-toggled="isToggled"
      :is-logged-in="isLoggedIn"
      :user="user"
      @logout="logout"
    />
    <router-view v-slot="{Component}">
      <transition
        name="fade"
        mode="out-in"
      >
        <component
          :is="Component"
          class="app-view"
          :user="user"
        />
      </transition>
    </router-view>
  </div>
</template>

<script>
import axios from 'axios';
import Navigation from './partials/Navigation.vue';
import Alert from './partials/Alert.vue';
import Message from './partials/Message';
import WebSocket from './partials/WebSocket';

export default {
  name: 'App',
  components: {
    Navigation,
    Alert,
  },
  data() {
    return {
      isLoggedIn: window.Laravel.loggedIn,
      user: window.Laravel.user ? window.Laravel.user : {},
      alerts: [],
      alertDismissTimeout: 10000,
      alertTimeouts: [],
      isToggled: false,
      darken: window.Darken ? window.Darken : false,
      websocket: new WebSocket(),
    };
  },
  created() {
    if (window.message) {
      this.setAlert(window.message.detail, window.message.type);
    }
  },
  mounted() {
    window.addEventListener('successMessage', (event) => {
      this.setAlert(event.detail, 'success');
    });

    window.addEventListener('errorMessage', (event) => {
      this.setAlert(event.detail, 'error');
    });
  },
  beforeUnmount() {
    window.removeEventListener('successMessage', (event) => {
      this.setAlert(event.detail, 'success');
    });

    window.removeEventListener('errorMessage', (event) => {
      this.setAlert(event.detail, 'error');
    });
  },
  methods: {
    switchDarken() {
      const vm = this;
      vm.darken = !vm.darken;
      axios.post('/api/darken', {
        darken: vm.darken,
      });
    },
    setAlert(message, alertType) {
      const vm = this;
      this.alerts.push({ message, alertType });
      this.alertTimeouts.push(
        setTimeout(() => {
          vm.removeAlert(0);
        }, vm.alertDismissTimeout),
      );
    },
    removeAlert(event) {
      const vm = this;
      this.alerts.splice(event, 1);
      clearTimeout(vm.alertTimeouts[event]);
      this.alertTimeouts.splice(event, 1);
    },
    logout() {
      this.successMessage = null;
      this.errorMessage = null;
      axios.post('/logout')
        .then(() => {
          window.location = '/';
        })
        .catch(() => {
          new Message('error', 'There was an issue logging out. Please clear cookies.').dispatch();
        });
    },
  },
};
</script>
<style scoped lang="scss">
  .app {
    @apply min-h-screen;

    &-masthead {
      @apply absolute inset-x-0 top-0 h-16 w-full border-solid border-b border-gray-200 py-2 -mb-12;
      right: .125rem;
    }

    &-masthead-background {
      @apply absolute h-full w-full inset-x-0 top-0 bg-center;
      background-image: url(https://paulbunyan.net/wp-content/themes/pbc2017/images/backgrounds/blue-swoosh.png);
      background-repeat: repeat;
      opacity: .1;
      z-index: 0;
    }

    &-masthead-logo {
      @apply absolute inset-y-0 right-0 h-full w-2/3 bg-right;
      z-index: 1000;
      background-repeat: no-repeat;
      @screen md {
        @apply w-1/3;
      }
    }

    &-view {
      @apply my-4 px-0 mt-16;
    }

    &.dark-mode {
      @apply bg-blue-900;
    }

    &.dark-mode &-masthead {
      @apply border-blue-800 bg-blue-900;
    }
  }

  @screen md {
    .app {
      @apply flex items-stretch
    }
    .app-masthead {
      @apply inset-x-0 h-20 py-3;
    }
    .app-view {
      @apply flex-col self-center flex-grow mt-20;
    }
  }

  @screen lg {
    .app-masthead {
      @apply h-24 py-4;
    }
    .app-view {
      @apply mt-24;
    }
  }
</style>
